"use client";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AppRouterCacheProvider } from "@mui/material-nextjs/v15-appRouter";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { PostHogProvider } from "./PostHogProvider";
import theme from "../../src/theme";
import { AuthContextProvider } from "../../utils/auth/useAuth";
import { PaymentDialogContextProvider } from "../../utils/usePaymentDialog";

// Create a client
const queryClient = new QueryClient();

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <AppRouterCacheProvider>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <PostHogProvider>
              <PayPalScriptProvider
                options={{
                  clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID || "",
                  locale: "pt_BR",
                  intent: "subscription",
                  vault: true,
                }}
              >
                <PaymentDialogContextProvider>
                  <CssBaseline />
                  {children}
                </PaymentDialogContextProvider>
              </PayPalScriptProvider>
            </PostHogProvider>
          </AuthContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </AppRouterCacheProvider>
  );
}
