import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Link/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/app/providers/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/projects/nextjs-app/ck-content.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["MenuItem"] */ "/app/projects/nextjs-app/layout_components/MenuItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/layout_components/UserBit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/AppBar/AppBar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/Divider/Divider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/Toolbar/Toolbar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/projects/nextjs-app/node_modules/@mui/material/Typography/Typography.js");
