import { initializeApp } from "firebase/app";
import {
  getAuth,
  fetchSignInMethodsForEmail as firebaseFetchSignInMethodsForEmail,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
  confirmPasswordReset as firebaseConfirmPasswordReset,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  verifyPasswordResetCode as firebaseVerifyPasswordResetCode,
  signOut as firebaseSignOut,
  onIdTokenChanged as firebaseOnIdTokenChanged,
} from "firebase/auth";

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
};

export const firebaseApp = initializeApp(config);

export const auth = getAuth();

export const fetchSignInMethodsForEmail = ({ email }) =>
  firebaseFetchSignInMethodsForEmail(auth, email)
    .then((signInMethods) => {
      return signInMethods;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(error, { errorCode, errorMessage });
    });

export const signInWithEmailAndPassword = ({ email, password }) =>
  firebaseSignInWithEmailAndPassword(auth, email, password);
// .then((userCredential) => {
//   const user = userCredential.user;

//   // console.log({ userCredential, user });
//   return;
// })
// .catch((error) => {
//   const errorCode = error.code;
//   const errorMessage = error.message;
//   console.error(error, { errorCode, errorMessage });
// });

export const createUserWithEmailAndPassword = ({ email, password }) =>
  firebaseCreateUserWithEmailAndPassword(auth, email, password);
// .then((signInMethods) => {
//   const user = userCredential.user;

//   // firebase.auth().updateProfile();

//   console.log({ userCredential, user }, firebase.auth().updateProfile);
//   return;
// })
// .catch((error) => {
//   const errorCode = error.code;
//   const errorMessage = error.message;
//   console.error(error, { errorCode, errorMessage });
// });

export const sendPasswordResetEmail = ({ email }) => firebaseSendPasswordResetEmail(auth, email);

export const verifyPasswordResetCode = ({ code }) => firebaseVerifyPasswordResetCode(auth, code);

export const confirmPasswordReset = ({ code, newPassword }) => firebaseConfirmPasswordReset(auth, code, newPassword);

export const signOut = async (callback?: Function) => {
  return firebaseSignOut(auth)
    .then(() => {
      // Sign-out successful.
      if (callback) {
        callback();
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export const onIdTokenChanged: (
  nextOrObserver: Parameters<typeof firebaseOnIdTokenChanged>[1],
  error?: Parameters<typeof firebaseOnIdTokenChanged>[2],
  completed?: Parameters<typeof firebaseOnIdTokenChanged>[3]
) => ReturnType<typeof firebaseOnIdTokenChanged> = (nextOrObserver, error, completed) =>
  firebaseOnIdTokenChanged(auth, nextOrObserver, error, completed);
