"use client";

import { useEffect, useState, useContext, Dispatch, SetStateAction, createContext } from "react";

import { collection, doc, getFirestore, onSnapshot } from "firebase/firestore";
import { usePostHog } from "posthog-js/react";

import { signOut, onIdTokenChanged, firebaseApp } from "./initFirebase";
import { mapUserData, UserAdditionalData, UserAuthData } from "./mapUserData";
import { removeUserCookie, setUserCookie, getUserFromCookie } from "./userCookies";
import { debug } from "../debug";

const db = getFirestore(firebaseApp);

const AuthContext = createContext<{
  user?: Partial<UserAuthData> | null;
  userIsLoading: boolean;
  logout: typeof signOut;
  userAdditionalData?: Partial<UserAdditionalData> | null;
  userAdditionalDataIsLoading: boolean;
  showLogin: boolean;
  setShowLogin: Dispatch<SetStateAction<boolean>>;
  updateUserAdditionalData: Function;
}>({
  user: undefined,
  userIsLoading: true,
  userAdditionalData: undefined,
  userAdditionalDataIsLoading: false,
  logout: signOut,
  showLogin: false,
  setShowLogin: () => {},
  updateUserAdditionalData: () => {},
});

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, userIsLoading, userAdditionalData, userAdditionalDataIsLoading, logout, updateUserAdditionalData } =
    useAuth();

  const posthog = usePostHog();

  useEffect(() => {
    // Consent is presumed for admins
    if (user?.id && userAdditionalData?.type === "admin") {
      return posthog.setPersonProperties({
        email: user.email,
        name: user.displayName,
        id: user.id,
        type: userAdditionalData.type,
      });
    }

    // TODO: Identify user after consent

    return () => {
      return posthog.reset();
    };
  }, [user?.id, posthog, user?.email, user?.displayName, userAdditionalData?.type]);

  const [showLogin, setShowLogin] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        user,
        userIsLoading,
        userAdditionalData,
        userAdditionalDataIsLoading,
        logout,
        showLogin,
        setShowLogin,
        updateUserAdditionalData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const {
    user,
    userIsLoading,
    logout,
    showLogin,
    setShowLogin,
    userAdditionalData,
    userAdditionalDataIsLoading,
    updateUserAdditionalData,
  } = useContext(AuthContext);

  return {
    user,
    userIsLoading,
    logout,
    showLogin,
    setShowLogin,
    userAdditionalData,
    userAdditionalDataIsLoading,
    updateUserAdditionalData,
  };
};

const useAuth = () => {
  const [user, setUser] = useState<UserAuthData | null>();
  const [userIsLoading, setUserIsLoading] = useState<boolean>(true);
  const [userAdditionalData, setUserAdditionalData] = useState<UserAdditionalData | null>();
  const [userAdditionalDataIsLoading, setUserAdditionalDataIsLoading] = useState<boolean>(false);

  const updateUserAdditionalData = (userAuthData?: Partial<UserAuthData>) => {
    if (!userAuthData) {
      setUserAdditionalData(null);
      setUserAdditionalDataIsLoading(false);
      return;
    }

    setUserAdditionalDataIsLoading(true);
    const docRef = doc(collection(db, "users"), userAuthData?.id || user?.id);

    const updateLocalUserData = (doc) => {
      setUserAdditionalData({ ...doc.data() });
      setUserAdditionalDataIsLoading(false);
    };

    onSnapshot(docRef, updateLocalUserData);
  };

  useEffect(() => {
    // Firebase updates the id token every hour, this
    // makes sure the react state and the cookie are
    // both kept up to date
    const cancelAuthListener = onIdTokenChanged((user) => {
      if (user) {
        const userAuthData = mapUserData(user);

        debug("user: %O", userAuthData);

        setUserCookie(userAuthData);
        setUser(userAuthData);

        updateUserAdditionalData(userAuthData);
      } else {
        removeUserCookie();
        setUser(null);
        updateUserAdditionalData();
      }

      setUserIsLoading(false);
    });

    const userFromCookie = getUserFromCookie();

    return () => {
      cancelAuthListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    userIsLoading,
    userAdditionalData,
    userAdditionalDataIsLoading,
    logout: signOut,
    updateUserAdditionalData,
  };
};

export { useAuth, AuthContextProvider, useAuthContext };
