"use client";

import React from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import Link from "next/link";
import { usePathname } from "next/navigation";

export function MenuItem({
  href,
  transition,
  children,
  ...props
}: {
  href: string;
  transition?: "no";
  children?: React.ReactNode;
} & ButtonProps) {
  const currentRoute = usePathname();

  const shouldHighlight = href === currentRoute;

  return (
    <Button
      color="primary"
      variant={shouldHighlight ? "contained" : "outlined"}
      disableElevation
      {...(transition === "no" ? {} : { component: Link, href })}
      {...props}
    >
      {children}
    </Button>
  );
}
