import { UserData } from "types";

export type UserAuthData = { id: string; displayName: string; email: string; token: string; photoURL: string };

export type UserAdditionalData = UserData;

export const mapUserData = (user): UserAuthData => {
  const { uid, displayName, email, xa, photoURL } = user;

  return {
    id: uid,
    displayName,
    email,
    token: xa,
    photoURL,
  };
};
