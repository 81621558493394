"use client";

import * as React from "react";

import ArrowBack from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useSearchParams } from "next/navigation";
import { useForm } from "react-hook-form";

import getErrorMessageForFirebaseError from "./getErrorMessageForFirebaseError";
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyPasswordResetCode as verifyPasswordResetCodeFunction,
  confirmPasswordReset as confirmPasswordResetFunction,
} from "../../utils/auth/initFirebase";

export enum Mode {
  initial = "initial",
  createNew = "createNew",
  login = "login",
  logged = "logged",
  recover = "recover",
  passwordResetEmailSent = "passwordResetEmailSent",
  verifyPasswordResetCode = "verifyPasswordResetCode",
  passwordResetCodeVerificationFailed = "passwordResetCodeVerificationFailed",
  confirmPasswordReset = "confirmPasswordReset",
  confirmPasswordSuccess = "confirmPasswordSuccess",
}

const propsFromRegister = (register, ...options) => {
  const { ref, ...props } = register(...options);

  return {
    inputRef: ref,
    ...props,
  };
};

export default function FormDialog({
  open,
  onClose,
  initialMode = Mode.initial,
}: {
  open: boolean;
  onClose: () => void;
  initialMode?: Mode;
}) {
  const searchParams = useSearchParams();

  const [mode, setMode] = React.useState(initialMode);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);

  const loginDialogMode = searchParams.get("mode") === "resetPassword" ? Mode.verifyPasswordResetCode : undefined;

  React.useEffect(() => {
    if (Boolean(loginDialogMode)) {
      setMode(Mode.verifyPasswordResetCode);
    }
  }, [loginDialogMode]);

  const initial = mode === Mode.initial;
  const logged = mode === Mode.logged;
  const login = mode == Mode.login;
  const createNew = mode === Mode.createNew;
  const recover = mode === Mode.recover;
  const passwordResetEmailSent = mode === Mode.passwordResetEmailSent;
  const verifyPasswordResetCode = mode === Mode.verifyPasswordResetCode;
  const passwordResetCodeVerificationFailed = mode === Mode.passwordResetCodeVerificationFailed;
  const confirmPasswordReset = mode === Mode.confirmPasswordReset;
  const confirmPasswordSuccess = mode === Mode.confirmPasswordSuccess;

  const notLogged = mode !== Mode.logged;

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      name: "",
      password: "",
    },
  });

  const code = searchParams.get("oobCode");

  React.useEffect(() => {
    if (verifyPasswordResetCode && !code) {
      return setMode(Mode.passwordResetCodeVerificationFailed);
    }

    if (verifyPasswordResetCode && code) {
      setIsLoading(true);
      verifyPasswordResetCodeFunction({ code })
        .then(() => {
          setMode(Mode.confirmPasswordReset);
        })
        .catch(() => {
          setMode(Mode.passwordResetCodeVerificationFailed);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
  }, [verifyPasswordResetCode, code]);

  React.useEffect(() => {
    return () => {
      if (!open) {
        setMode(Mode.initial);
      }
    };
  });

  const onSubmit = (formData) => {
    setError(null);
    setIsLoading(true);

    if (mode === Mode.initial) {
      fetchSignInMethodsForEmail(formData)
        .then((signInMethods) => {
          if (signInMethods?.length === 0) {
            setMode(Mode.createNew);
            return;
          }
        })
        .catch((error) => {
          setError("Erro ao verificar e-mail, por favor tente novamente ou entre em contato");
        })
        .finally(() => {
          setIsLoading(false);
        });

      setMode(Mode.login);
      return;
    }

    if (mode === Mode.login) {
      signInWithEmailAndPassword(formData)
        .then((result) => {
          setMode(Mode.logged);
          return;
        })
        .catch((error) => {
          setError(getErrorMessageForFirebaseError(error.code));
        })
        .finally(() => {
          setIsLoading(false);
        });

      return;
    }

    if (mode === Mode.createNew) {
      createUserWithEmailAndPassword(formData)
        .then((result) => {
          setMode(Mode.logged);
          return;
        })
        .catch((error) => {
          setError(getErrorMessageForFirebaseError(error.code));
        })
        .finally(() => {
          setIsLoading(false);
        });

      return;
    }

    if (mode === Mode.recover) {
      sendPasswordResetEmail(formData)
        .then((result) => {
          setMode(Mode.passwordResetEmailSent);
          return;
        })
        .catch((error) => {
          setError(getErrorMessageForFirebaseError(error.code));
        })
        .finally(() => {
          setIsLoading(false);
        });

      return;
    }

    if (confirmPasswordReset) {
      confirmPasswordResetFunction({ code, newPassword: formData.password })
        .then((result) => {
          setMode(Mode.confirmPasswordSuccess);
          return;
        })
        .catch((error) => {
          setError(getErrorMessageForFirebaseError(error.code));
        })
        .finally(() => {
          setIsLoading(false);
        });

      return;
    }
  };

  const handleClose = () => {
    onClose();
    setError(null);
  };

  const nameField = (
    <TextField
      sx={{ mt: 3 }}
      autoFocus={createNew ? true : false}
      id="name"
      label="Nome e sobrenome"
      fullWidth
      {...propsFromRegister(register, "name")}
    />
  );

  const passwordField = (
    <TextField
      type="password"
      sx={{ mt: 3, flexBasis: "100%" }}
      autoFocus={createNew ? false : true}
      id="password"
      label="Senha"
      fullWidth
      {...propsFromRegister(register, "password")}
    />
  );

  const loginField = (
    <Box display="flex" flexWrap="wrap">
      {passwordField}
      <Link
        href="#"
        marginTop={1}
        marginLeft="auto"
        onClick={() => {
          setMode(Mode.recover);
        }}
      >
        Esqueci minha senha
      </Link>
    </Box>
  );

  const notLoggedContent = (
    <>
      <DialogContentText sx={{ mb: 3 }}>
        {initial && "Digite seu e-mail"}
        {createNew && "Preencha os campos para criar sua conta"}
        {login && "Digite sua senha para fazer login"}
        {recover && "Enviaremos um e-mail com um link para alterar sua senha"}
      </DialogContentText>

      <TextField id="email" label="Email" type="email" fullWidth autoFocus {...propsFromRegister(register, "email")} />

      <Fade in={createNew}>{createNew ? nameField : <div></div>}</Fade>

      <Fade in={login || createNew}>{login || createNew ? loginField : <div></div>}</Fade>
    </>
  );

  const backButton = !passwordResetEmailSent && (
    <IconButton
      onClick={() => {
        setMode(Mode.initial);
        setError(null);
      }}
    >
      <ArrowBack />
    </IconButton>
  );

  const titleText = (
    <>
      {initial && "Acesse ou crie sua conta"}
      {login && "Acesse"}
      {createNew && "Crie sua conta"}
      {recover && "Recuperar senha"}
      {verifyPasswordResetCode && "Verificando..."}
      {confirmPasswordReset && "Defina uma nova senha"}
    </>
  );

  const content = (
    <form onSubmit={handleSubmit(onSubmit)}>
      {(backButton || titleText) && (
        <DialogTitle>
          {backButton && <>{backButton} </>}
          {titleText}
        </DialogTitle>
      )}

      <DialogContent>
        {(initial || login || createNew || recover) && notLoggedContent}

        {error && (
          <Typography marginTop={2} color="error">
            {error}
          </Typography>
        )}

        {logged && (
          <>
            <Typography variant="h6" textAlign="center" gutterBottom>
              Seja bem vindo!
            </Typography>
            <Typography textAlign="center">Você já pode utilizar o site normalmente</Typography>
          </>
        )}

        {passwordResetEmailSent && (
          <>
            <Typography variant="h6" textAlign="center" gutterBottom>
              E-mail de recuperação enviado
            </Typography>
            <Typography textAlign="center">
              Clique no link que enviamos no seu e-mail para criar uma nova senha
            </Typography>
          </>
        )}

        {verifyPasswordResetCode && null}

        {passwordResetCodeVerificationFailed && (
          <>
            <Typography variant="h6" textAlign="center" gutterBottom>
              Código de recuperação inválido
            </Typography>
            <Typography textAlign="center">Tente acessar ou recuperar sua senha novamente</Typography>
          </>
        )}

        {confirmPasswordReset && passwordField}

        {confirmPasswordSuccess && (
          <>
            <Typography variant="h6" textAlign="center" gutterBottom>
              Senha alterada com sucesso!
            </Typography>
            <Typography textAlign="center">Você já pode utilizar o site normalmente</Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {!passwordResetEmailSent && !logged && !confirmPasswordSuccess && !passwordResetCodeVerificationFailed && (
          <>
            <Button onClick={handleClose}>Cancelar</Button>
          </>
        )}

        {initial && (
          <Button variant="contained" type="submit">
            Próximo
          </Button>
        )}

        {login && (
          <Button variant="contained" type="submit">
            Entrar
          </Button>
        )}

        {createNew && (
          <Button variant="contained" type="submit">
            Criar conta
          </Button>
        )}

        {recover && (
          <Button variant="contained" type="submit">
            Enviar
          </Button>
        )}

        {confirmPasswordReset && (
          <Button variant="contained" type="submit">
            Enviar
          </Button>
        )}

        {(logged || confirmPasswordSuccess || passwordResetEmailSent || passwordResetCodeVerificationFailed) && (
          <Button variant="contained" onClick={handleClose}>
            Fechar
          </Button>
        )}
      </DialogActions>
    </form>
  );

  const contentLoading = (
    <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 250 }}>
      <CircularProgress />
    </DialogContent>
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      {process.env.NODE_ENV !== "production" && (
        <select
          style={{ position: "fixed", bottom: 0, right: 0 }}
          value={mode}
          onChange={(event) => {
            setMode(Mode[event.target.value]);
          }}
        >
          {Object.keys(Mode).map((mode) => {
            return (
              <option key={mode} value={mode}>
                {mode}
              </option>
            );
          })}
        </select>
      )}
      {isLoading ? contentLoading : content}
    </Dialog>
  );
}
