"use client";

import React, { useEffect } from "react";

import AccountCircle from "@mui/icons-material/AccountCircle";
import { useMediaQuery, useTheme } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Link from "next/link";
import { useSearchParams } from "next/navigation";

import LoginDialog, { Mode } from "../components/LoginDialog";
import { useAuthContext } from "../utils/auth/useAuth";

const UserBit = ({ component }: { component: React.ReactElement }) => {
  const { user, logout, showLogin, setShowLogin } = useAuthContext();
  const searchParams = useSearchParams();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const showMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const loginDialogMode = searchParams.get("mode") === "resetPassword" ? Mode.verifyPasswordResetCode : undefined;

  useEffect(() => {
    if (Boolean(loginDialogMode)) {
      setShowLogin(true);
    }
  }, [loginDialogMode]);

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      handleCloseMenu();
    }
  }

  const loginDialog = (
    <LoginDialog
      open={showLogin}
      onClose={() => {
        setShowLogin(false);
      }}
      initialMode={loginDialogMode}
    />
  );

  const theme = useTheme();

  const mediaQuery = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      {user
        ? React.cloneElement(
            component,
            {
              onClick: handleOpenMenu,
              endIcon: <AccountCircle />,
            },
            mediaQuery ? "Minha conta" : "Conta"
          )
        : React.cloneElement(
            component,
            {
              onClick: () => {
                setShowLogin(true);
              },
            },
            mediaQuery ? "Acessar minha conta" : "Conta"
          )}

      {loginDialog}

      <Popper id="menu-appbar" anchorEl={anchorEl} transition disablePortal placement="bottom" open={showMenu}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList autoFocusItem={showMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={handleCloseMenu} component={Link} href="/minha-conta/#perfil">
                    Perfil
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu} component={Link} href="/minha-conta/#assinatura">
                    Assinatura
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu} component={Link} href="/minha-conta/#endereco">
                    Meu endereço
                  </MenuItem>

                  <MenuItem onClick={handleCloseMenu} component={Link} href="/minha-conta/#faturas">
                    Faturas
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      logout();
                      handleCloseMenu();
                    }}
                  >
                    Sair
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default UserBit;
